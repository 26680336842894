<template>
  <div id="app-container" class="row align-items-center justify-content-center">
    <div class="terminal-bg">
      <section id="terminal">
        <router-view />
      </section>
    </div>
    <div class="text">
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        >
      </h2>
      <h1>
        Ratuj dzieci przed niepotrzebnym cierpieniem
      </h1>
      <p>
        Twój gest jest wsparciem dla Centrum Chorób Rzadkich w zakupie Angiografu Optycznej Tomografii
Koherentnej, który gwarantuje przeprowadzenie szybkiej procedury diagnostycznej oka, pozwala na ultra
precyzyjne wykrycie wczesnych zmian i co najważniejsze, jest bezinwazyjny oraz bezbolesny. To ochroni
małych pacjentów przed niezrozumiałym cierpieniem podczas tradycyjnego badania.
      </p>

      <p>Dzięki Twojej pomocy:</p>
      <ul>
        <li>
          zakupimy profesjonalny sprzęt do szybkiej i bezpiecznej diagnostyki oka u dzieci,
        </li>
        <li>
          ochronimy małych pacjentów przed długim i nieprzyjemnym badaniem,
        </li>
        <li>
          zapewnimy lepszą i szybszą diagnozę pacjentom.
        </li>
      </ul>
      <p>
        Twoja wpłata trafi bezpośrednio na konto<br />
        <strong>Fundacji „Potrafię Pomóc”</strong> i będzie przeznaczona na zakup
sprzętu dla Centrum Diagnostyczno-Terapeutycznego Chorób Rzadkich.
      </p>
      <img :src="require('@/assets/img/logo.png')" alt="" />
      <img :src="require('@/assets/img/logo-centrum.png')" alt="" />
    </div>
    <div class="mobile-text" v-if="showText">
      <div>
        <img :src="require('@/assets/img/logo.png')" alt="" />
        <img :src="require('@/assets/img/logo-centrum.png')" alt="" />
      </div>
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        >
      </h2>
      <h1>
        Ratuj dzieci przed niepotrzebnym cierpieniem
      </h1>
      <p>
        Twój gest jest wsparciem dla Centrum Chorób Rzadkich w zakupie Angiografu Optycznej Tomografii
Koherentnej, który gwarantuje przeprowadzenie szybkiej procedury diagnostycznej oka, pozwala na ultra
precyzyjne wykrycie wczesnych zmian i co najważniejsze, jest bezinwazyjny oraz bezbolesny. To ochroni
małych pacjentów przed niezrozumiałym cierpieniem podczas tradycyjnego badania.
      </p>

      <p>Dzięki Twojej pomocy:</p>
      <ul>
        <li>
          zakupimy profesjonalny sprzęt do szybkiej i bezpiecznej diagnostyki oka u dzieci,
        </li>
        <li>
          ochronimy małych pacjentów przed długim i nieprzyjemnym badaniem,
        </li>
        <li>
          zapewnimy lepszą i szybszą diagnozę pacjentom.
        </li>
      </ul>
      <p>
        Twoja wpłata trafi bezpośrednio na konto<br />
        <strong>Fundacji „Potrafię Pomóc”</strong> i będzie przeznaczona na zakup
sprzętu dla Centrum Diagnostyczno-Terapeutycznego Chorób Rzadkich.
      </p>
    </div>
    <div class="ibtn" v-on:click="showText = !showText">
      <img :src="require('@/assets/img/i_btn.png')" alt="" v-if="!showText" />
      <img :src="require('@/assets/img/x_btn.png')" alt="" v-if="showText" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.css";
</style>
